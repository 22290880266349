import React, { useState } from "react";

const ErrorStickyTop = ({ message }) => {
    const [show, setShow] = useState(true);

    return (
        show && (
            <div className="sticky flex top-0 p-2 bg-red text-white px-4 z-10">
                <div className="flex flex-wrap">{message}</div>
                <div className="flex-1" />
                <button
                    className="float-right text-white text-sm"
                    onClick={() => setShow(false)}
                >
                    <i className="fas fa-times fa-fw" />
                </button>
            </div>
        )
    );
};

export { ErrorStickyTop };
