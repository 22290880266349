import React, { useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";

export const FileResourceAccessProvider = ({ idToken, children }) => {
    const { CPAS_API_SERVICE_URI } = useStaticQuery(graphql`
        query FileResourceAccessProviderQuery {
            site {
                siteMetadata {
                    CPAS_API_SERVICE_URI
                }
            }
        }
    `).site.siteMetadata;

    const [ready, setReady] = useState(null);

    useEffect(() => {
        fetch(`${CPAS_API_SERVICE_URI}/auth`, {
            method: "POST",
            mode: "cors",
            credentials: "include",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${idToken}`,
            },
        }).then((res) => {
            if (res.ok) {
                setReady("OK");
            } else {
                setReady("NOT_OK");
            }
        });
    }, [CPAS_API_SERVICE_URI, idToken]);

    if (ready === "OK") {
        return <>{children}</>;
    } else if (ready === "NOT_OK") {
        return "Not allowed";
    }

    return null;
};
