import React, { useContext, useState, useEffect } from "react";
import { graphql, navigate } from "gatsby";
import { Router } from "@reach/router";
import Elm from "react-elm-components";

import { CurrentUserContext } from "../providers/auth";
import { FileResourceAccessProvider } from "../providers/file-resource-access";
import {
    VegaProvider,
    VegaWebComponentsContext,
    EchartProvider,
    EchartWebComponentsContext,
} from "../providers/custom-elements";
import { getAccountStorage } from "../js/cpasApi";
import { JobTypes } from "../helpers/const";

import Layout from "../components/layout";
import Seo from "../components/seo";
import SignInAgain from "../components/sign-in-again";
import Loading from "../components/loading";
import { ErrorStickyTop } from "../components/error";

import enclosedAnglesExplainImage from "../assets/images/explain/enclosed_angles_explain.png";
import NumberOfCellExplainImage from "../assets/images/explain/number_of_cell_explain.png";
import RelativeLengthRatioExplainImage from "../assets/images/explain/relative_length_ratio_explain.png";

import ListApp from "../ElmApp/Page/Job/List.elm";
import TestReportApp from "../ElmApp/Page/Job/TestReport.elm";

const Routing = (props) => {
    let { CPAS_API_SERVICE_URI, CPAS_VIS_URI } = props.data.site.siteMetadata;

    return (
        <Layout>
            <VegaProvider>
                <EchartProvider>
                    <Router>
                        <ListPage
                            path="job/:jobType"
                            CPAS_API_SERVICE_URI={CPAS_API_SERVICE_URI}
                            CPAS_VIS_URI={CPAS_VIS_URI}
                        />
                        <TestReportPage
                            path="job/:jobId/test-report"
                            CPAS_API_SERVICE_URI={CPAS_API_SERVICE_URI}
                        />
                    </Router>
                </EchartProvider>
            </VegaProvider>
        </Layout>
    );
};

export default Routing;

export const query = graphql`
    query {
        site {
            siteMetadata {
                CPAS_API_SERVICE_URI
                CPAS_VIS_URI
            }
        }
    }
`;

// pages

const ListPage = ({ CPAS_API_SERVICE_URI, CPAS_VIS_URI, jobType }) => {
    const currentUser = useContext(CurrentUserContext);

    const [idToken, setIdToken] = useState(null);
    const [loading, setLoading] = useState(true);
    const [storage, setStorage] = useState(null);
    const [storageRefreshTime, setStorageRefreshTime] = useState(Date.now());
    const [err, setErr] = useState("");

    useEffect(() => {
        window.sessionStorage.removeItem("redirectAfterLogin");
        if (!CPAS_API_SERVICE_URI || !CPAS_VIS_URI) {
            return;
        }
        setLoading(true);
        currentUser
            .getIdToken()
            .then((token) => {
                if (token) {
                    setIdToken(token);
                    getAccountStorage({
                        cpasServiceEndpoint: CPAS_API_SERVICE_URI,
                        idToken: token,
                    })
                        .then((d) => setStorage(d.data))
                        .catch((err) => {
                            setErr(err);
                            setStorage({});
                        });
                }
            })
            .catch((err) => console.error(err))
            .finally(() => setLoading(false));
    }, [CPAS_API_SERVICE_URI, currentUser, CPAS_VIS_URI, storageRefreshTime]);

    const activeTabCss = (active) =>
        "mr-12 p-4" + (active ? " border-green1 border-solid border-b-4" : "");

    const activeBtnCss = (active) => (active ? "text-green1" : "");

    const byteToGBTxt = (b) =>
        (typeof b === "number"
            ? Math.round((b * 1024) / 1000 / 1000 / 1000)
            : "---") + " GB";

    const listView = () => (
        <main className="flex flex-col flex-grow h-full">
            <div className="flex flex-row items-stretch bg-grey-light h-12">
                <div className="flex flex-row items-center flex-1 ml-4">
                    Computational Jobs
                </div>
            </div>
            <div className="flex flex-row items-stretch bg-grey3 px-10">
                <ul className="list-reset flex ml-8 mt-4">
                    <li className={activeTabCss(jobType === JobTypes.meshgen)}>
                        <button
                            className={activeBtnCss(
                                jobType === JobTypes.meshgen
                            )}
                            onClick={() => navigate("/job/" + JobTypes.meshgen)}
                        >
                            Mesh Generation
                        </button>
                    </li>
                    <li className={activeTabCss(jobType === JobTypes.realsim)}>
                        <button
                            className={activeBtnCss(
                                jobType === JobTypes.realsim
                            )}
                            onClick={() => navigate("/job/" + JobTypes.realsim)}
                        >
                            Real Simulation
                        </button>
                    </li>
                    <li className={activeTabCss(jobType === JobTypes.swtest)}>
                        <button
                            className={activeBtnCss(
                                jobType === JobTypes.swtest
                            )}
                            onClick={() => navigate("/job/" + JobTypes.swtest)}
                        >
                            Shallow Water Test
                        </button>
                    </li>
                </ul>
                <div className="flex-1"></div>
                <div className="flex flex-row items-center px-4">
                    Total storage used:{" "}
                    {!storage ? (
                        "Loading"
                    ) : (
                        <>
                            {byteToGBTxt(storage.used)} /{" "}
                            {byteToGBTxt(storage.limit)}
                        </>
                    )}
                </div>
            </div>
            {err && <ErrorStickyTop message={err} />}
            <Elm
                key={idToken}
                src={ListApp.Elm.Page.Job.List}
                flags={{
                    cpasServiceEndpoint: CPAS_API_SERVICE_URI,
                    idToken,
                    jobType,
                }}
                ports={(ports) => {
                    ports.gotoTestReport.subscribe((jobId) => {
                        navigate(`/job/${jobId}/test-report`);
                    });
                    ports.gotoOrder.subscribe(({ projectId, jobId }) => {
                        navigate(`/order/p/${projectId}/j/${jobId}`);
                    });
                    ports.gotoVis.subscribe(() => {
                        currentUser.getNewAccessToken().then((accessToken) => {
                            window.open(
                                `${CPAS_VIS_URI}/hub/login?access_token=${accessToken}`
                            );
                        });
                    });
                    ports.refreshStorage.subscribe(() => {
                        setStorageRefreshTime(Date.now());
                    });
                }}
            />
        </main>
    );

    return (
        <>
            <Seo title="My Jobs" />
            {loading ? <Loading /> : idToken ? listView() : <SignInAgain />}
        </>
    );
};

const TestReportPage = ({ CPAS_API_SERVICE_URI, jobId }) => {
    const currentUser = useContext(CurrentUserContext);
    const vega = useContext(VegaWebComponentsContext);
    const echart = useContext(EchartWebComponentsContext);

    const [view, setView] = useState(<Loading />);
    const [idToken, setIdToken] = useState(null);

    useEffect(() => {
        currentUser.getIdToken().then((idToken) => {
            setIdToken({ idToken });
        });
    }, [currentUser]);

    useEffect(() => {
        if (idToken && idToken.idToken && vega.ready && echart.ready) {
            setView(
                <FileResourceAccessProvider idToken={idToken.idToken}>
                    <Elm
                        key={idToken.idToken}
                        src={TestReportApp.Elm.Page.Job.TestReport}
                        flags={{
                            cpasServiceEndpoint: CPAS_API_SERVICE_URI,
                            idToken: idToken.idToken,
                            jobId,
                            enclosedAnglesExplainImage: enclosedAnglesExplainImage,
                            numberOfCellExplainImage: NumberOfCellExplainImage,
                            relativeLengthRatioExplainImage: RelativeLengthRatioExplainImage,
                        }}
                        ports={(ports) => {
                            ports.scrollToBottom.subscribe(() => {
                                window.scrollTo(0, document.body.scrollHeight);
                            });

                            ports.gotoOrderMoreIteration.subscribe(
                                ({ projectId, jobId }) => {
                                    navigate(
                                        `/order/p/${projectId}/j/${jobId}`
                                    );
                                }
                            );

                            ports.gotoRefineMeshSpec.subscribe((projectId) => {
                                navigate(`/meshspec/new/from/${projectId}`);
                            });
                        }}
                    />
                </FileResourceAccessProvider>
            );
        }
    }, [idToken, vega, echart, CPAS_API_SERVICE_URI, jobId]);

    return (
        <>
            <Seo title="Mesh Generation Report" />
            {view}
        </>
    );
};
