import axios from "axios";

const headers = ({ idToken }) =>
    Object.assign({
        Authorization: "Bearer " + idToken,
    });

const catchErr = (err, reject) => {
    const UnexpectedErr = "Unexpected error.";
    if (err.response) {
        // client received an error response (e.g. 5xx, 4xx)
        const code = err.response.status;
        if (code === 400 || code === 404) {
            reject((err.response.error || {}).message || UnexpectedErr);
        } else if (code === 401) {
            reject("Something went wrong. Please refresh page and try again.");
        } else if (code === 403) {
            reject("Forbidden.");
        } else {
            reject(UnexpectedErr);
        }
    } else if (err.request) {
        // client never received a response, or request never left
        reject(
            "Poor network connection. Please check your network connection and try again."
        );
    } else {
        reject(UnexpectedErr);
    }
};

const axiosGet = ({ url, idToken }) =>
    new Promise((resolve, reject) => {
        axios
            .get(url, { headers: headers({ idToken }) })
            .then((d) => resolve(d))
            .catch((err) => catchErr(err, reject));
    });

const axiosPost = ({ url, idToken }) =>
    new Promise((resolve, reject) => {
        axios
            .post(url, {}, { headers: headers({ idToken }) })
            .then((d) => resolve(d))
            .catch((err) => catchErr(err, reject));
    });

const axiosDelete = ({ url, idToken }) =>
    new Promise((resolve, reject) => {
        axios
            .delete(url, { headers: headers({ idToken }) })
            .then((d) => resolve(d))
            .catch((err) => catchErr(err, reject));
    });

/* Exported functions: Start */
export const getAccountStorage = ({ cpasServiceEndpoint, idToken }) =>
    axiosGet({ url: `${cpasServiceEndpoint}/account/storage`, idToken });

export const getMeshspecs = ({
    cpasServiceEndpoint,
    idToken,
    l = 50,
    next = null,
}) => {
    var nextPage = !!next ? `&s=${next}` : ``;
    return axiosGet({
        url: `${cpasServiceEndpoint}/mesh/spec/list?l=${l}${nextPage}`,
        idToken,
    });
};

export const getMarketingSiteContent = ({ cpasMarketingSiteEndpoint, uuid }) =>
    axiosGet({ url: `${cpasMarketingSiteEndpoint}/${uuid}` });

export const copyMeshspecs = ({ cpasServiceEndpoint, idToken, specId }) =>
    axiosPost({
        url: `${cpasServiceEndpoint}/mesh/spec/${specId}/copy`,
        idToken,
    });

export const deleteMeshspec = ({ cpasServiceEndpoint, idToken, specId }) =>
    axiosDelete({
        url: `${cpasServiceEndpoint}/mesh/spec/${specId}`,
        idToken,
    });

/* Exported functions: End */
